               |


<template>
  <div class="animated fadeIn">
    <h1 id="meetcarlbot">Meet Carlbot</h1>

    <p>Before, you needed a bot for stream announcements, a bot for custom commands, a bot for role management, and on and on...</p>

    <p>
      Carlbot does what the most popular bots do but does it
      <em>better, faster</em>, and without the meme commands that spam and annoy you. Carlbot has been used to reduce the number of bots needed in a server from 3 to 4 or more… to just 1.
    </p>

    <h1 id="onlinedashboard">Online Dashboard</h1>

    <ul>
      <li>Access Carlbot’s dashboard from a web browser at your computer, on your tablet; even from your phone</li>

      <li>Includes a simple-to-use embed builder with color picker for simple jobs or custom colors for those of you that need it</li>

      <li>Manage the bot's prefix and nickname</li>

      <li>Command permissions</li>

      <li>Welcome/farewell messages (with embeds)</li>

      <li>Create and manage the starboard directly from the web</li>

      <li>Manage automod settings</li>

      <li>Configure logging</li>

      <li>Set up autoroles and sticky roles</li>

      <li>Create and edit custom commands, responses and repeating announcements directly from the website (with embeds)</li>

      <li>Blazing fast, sleek design and instant updates. You can't go wrong.</li>
    </ul>

    <h1
      id="rolemanagementputtheworkinyourmembershandsorcarlbots"
    >Role Management - Put the work in your member's hands, or Carlbot's</h1>

    <h5 id="autoroles">Autoroles</h5>

    <ul>
      <li>Carlbot can assign a role to users as they join your server, and with stickyroles – reassign that role if they leave and rejoin.</li>
    </ul>

    <h5 id="delayedautoroles">Delayed Autoroles</h5>

    <ul>
      <li>Great for assigning a role that keeps members in a #rules channel long enough to read them (hopefully)</li>
    </ul>

    <h5 id="reactionroles">Reaction Roles</h5>

    <ul>
      <li>
        <p>
          Carlbot's reaction roles are
          <strong>
            <a href="https://i.imgur.com/2A4EFbK.mp4">fast</a>
          </strong>, extremely easy to set up, customizable and don't limit you. Unlike some other bots offering reaction roles, Carlbot allows for 'unique roles' meaning members can only pick up one role from a message (useful for colors).
        </p>
      </li>

      <li>
        <p>What limits does it have?</p>

        <p>
          One role can't be assigned on two different messages; other than that?
          <em>Nothing</em>.
        </p>
      </li>
    </ul>

    <p>&nbsp; &nbsp; &nbsp; &nbsp; ‣ Want to put one role on one message per channel? Go for it!</p>

    <p>&nbsp; &nbsp; &nbsp; &nbsp; ‣ Need to add 250 roles to a single message? Odd, but technically doable.</p>

    <ul>
      <li>
        <code>!rr make</code> is an interactive setup command to get started with reaction roles. It aids you in creating an embed and adding reaction roles to it. Additionally, to mark a message as 'unique' (members can only pick up one role from it) use
        <code>!rr unique &lt;msg_id&gt;</code> you can see the message ids off all reaction role messages by typing
        <code>!rr show</code>
        <img src="https://i.imgur.com/zlTF5QS.png" alt="ReactionRoles" />
      </li>
    </ul>

    <h1
      id="automodgoodbyetomentionspamraidsandmore"
    >Automod - Goodbye to mentionspam, raids, and more</h1>

    <p>Is it against your server’s rules to post invite links to other servers? Carlbot’s automod features will automatically carry out specified punishment actions including the ability to delete the message immediately, temporarily banning or muting the sender, etc.</p>

    <blockquote>
      <p>
        <strong>You can also:</strong>
        <br />• Apply multiple punishment actions to an infractive example with the click of a button
        <br />• PM/DM users to inform them why the mod action was taken
        <br />• Defer infractions to a specific channel for your mod team to decide what action to take next
        <br />• Use Carlbot's mod-log features, allowing moderator's to take responsibility for their actions
        &nbsp; &nbsp; &nbsp; &nbsp;
        <img
          src="https://i.imgur.com/jBvGa4b.png"
          alt="warn"
        />
        &nbsp; &nbsp; &nbsp; &nbsp;
        <img src="https://i.imgur.com/YRYtFYE.png" alt="modlog" />
      </p>
    </blockquote>

    <h1 id="granularpermissionsystem">Granular Permission System</h1>

    <ul>
      <li>Give each command its own set of rules</li>

      <li>Manage commands in bulk on the website</li>

      <li>Add global settings</li>

      <li>Redirect output, mute output, keep spammy commands in a designated bot-channel</li>

      <li>
        Use the bot to its full potential without ever annoying anyone
        <img
          src="https://i.imgur.com/cRTz2rf.png"
          alt
        />
      </li>
    </ul>

    <h1
      id="extensiveloggingtheconsequencesofeveryactareincludedintheactitself"
    >Extensive Logging - "The consequences of every act are included in the act itself"</h1>

    <p>No one is a Big Brother quite like Carlbot. Know when members join/leave/create invites/change their nickname or avatar and much more by logging server and user actions with Carlbot. With the possibility of splitting logging into five separate channels, the bot makes sure you get all the info with zero clutter.</p>

    <blockquote>
      <p>
        A user edited a message to try to cover their tracks of behavior against your server’s rules? Carlbot will show you that.
        <img
          src="https://i.imgur.com/VIeI7W9.png"
          alt="MessageEdit"
        />
      </p>
    </blockquote>

    <h1
      id="customizationletcarlbotdoevenmoreforyouseriously"
    >Customization - Let Carlbot do even more for you. Seriously.</h1>

    <h5 id="tags">Tags</h5>

    <ul>
      <li>
        Custom commands are easy to set up, but offer powerful 'blocks' for those willing to make the effort. Take for instance the following example:
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <strong>!tag + mathskillz $author really #{4|Likes~hates} math</strong>
      </li>
    </ul>

    <p>
      If someone used the
      <strong>!mathskillz</strong> command Carlbot will pull one response more often than the other based on the N| syntax:
      | Command | % of instances it is invoked | ..it will respond: |
      | :-------------: |:-------------:| :-----:|
      |
      <code>!mathskillz</code> | 80 | “@UserThatMadeTheTag really
      <strong>likes</strong> math” |
      |
      <code>!mathskillz</code> | 20 | “@UserThatMadeTheTag really
      <strong>hates</strong> math” |
    </p>

    <h5 id="customwelcome">Custom Welcome</h5>

    <ul>
      <li>
        Want to give users a good first impression? Carlbot can send messages to users when they join, with the ability to use several variables, such as their name and their ordinal number of the server’s member count.
        <strong>Now with embeds</strong>
      </li>
    </ul>

    <h5 id="customembeds">Custom Embeds</h5>

    <ul>
      <li>Make highly readable embeds that make channels for rules, expections, FAQs, and more</li>

      <li>
        Before you ask:
        <em>of course</em> you can use them in autofeeds, custom tags, and more. Carlbot is just that good.
      </li>
    </ul>

    <blockquote>
      <p>
        Example of a custom !bard tag built for a Pathfinder server:
        <img
          src="https://i.imgur.com/6hM3rxv.png"
          alt="embed"
        />
      </p>

      <h5 id="commandmanagement">Command Management</h5>

      <ul>
        <li>
          Carlbot allows you to control who can use what commands. By default certain commands are
          <code>modonly</code> as described in the documentation
        </li>

        <li>Worried about users spamming commands that have images/links for output? Specify a channel for bot output to keep chatty channels free of clutter</li>
      </ul>
    </blockquote>

    <h1 id="communitybuilding">Community Building</h1>

    <h5 id="starboard">Starboard</h5>

    <ul>
      <li>Like mum pinning your drawings to the refrigerator, the Starboard is a place for your users to save posts by using the ⭐️emoji</li>

      <li>
        Posts need to meet a minimum number of stars recieved – which, of course, you customize
        <img
          src="https://i.imgur.com/tb1lL0p.png"
          alt="Starboard"
        />
      </li>
    </ul>

    <h5 id="suggestions">Suggestions</h5>

    <ul>
      <li>Allow members to make suggestions for the server</li>

      <li>Carlbot will number the suggestion, post it to the specified channel, and let your members upvote/downvote the suggestion</li>

      <li>
        Carlbot will create a suggestions channel for you by using
        <code>!suggestion channel</code> - or make your own channel, and set Carlbot to ouput suggestions there
        <img
          src="https://i.imgur.com/Fes2OfB.png"
          alt="Suggestion"
        />
      </li>

      <li>Customize how many upvotes are needed to change the color on the suggestion embed which can symbolize that enough votes were gained to make the admin/mod team consider the suggestion</li>

      <li>If you'd like, Carlbot will even DM/PM users upon approval/denial of a suggestion they submitted</li>

      <li>
        Using a suggestion-log channel will keep all of your server members up to speed on the status of suggestions that have had decision actions carried out
        <img
          src="https://i.imgur.com/LRdcTGi.png"
          alt="SuggestionLog"
        />
      </li>
    </ul>

    <h5 id="feedsautofeeds">Feeds/Autofeeds</h5>

    <ul>
      <li>Feeds/Autofeeds – Feeds are a way for you to make announcements and ping a specific role without having to deal with the annoyances and potential abuse from either having a pingable role or manually toggling inbetween pingable and not</li>

      <li>
        Automatic feeds can be seen as group reminders as they share a lot of functionality with reminders:
        <img
          src="https://i.imgur.com/tShOeph.png"
          alt="Autofeed"
        />
      </li>
    </ul>

    <h5 id="twitchnotifications">Twitch Notifications</h5>

    <ul>
      <li>Comes with the ability to let non-mods add a specified number of channels (useful for allowing members to add themselves)</li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "About",
  components: {}
};
</script>






